import React from 'react';
import './App.css';
import useApiAvailability from "./services/useApiAvailability";
import RecordingComponent from "./components/audio/RecordingComponent";
import {API_URL} from "./constants/constants";

// Check if the API_URL is available


function Status() {
    return <div style={{color: useApiAvailability() ? "green" : "red"}}>
        Backend is {useApiAvailability() ? "online" : "offline"}</div>;
}

function NoBackendAlert() {
    return <div style={{color: "red"}}>
        RECORDER_BACKEND_URL is not set
    </div>;
}


function App() {
    return (
        <div className="App">
            <header className="App-header">
                {!API_URL && <NoBackendAlert/>}
                <Status/>
                <br/>
                <div>
                    Start speaking with the word "TASK"<br/> End with the word "STOP".
                </div>
                <br/>
                <RecordingComponent/>
            </header>
        </div>
    );
}

export default App;
