import { useState, useEffect } from 'react';
import {API_PING_URL} from "../constants/constants";

/**
 * Hook to check if the API is available.
 * Returns true if the API is available, false otherwise.
 */
const useApiAvailability = () => {
    // Define the state as boolean or null
    const [isAvailable, setIsAvailable] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAvailability = async () => {
            try {
                const response = await fetch(API_PING_URL);
                console.log("checkAvailability response", response);
                if (response.ok) {
                    setIsAvailable(true);
                } else {
                    setIsAvailable(false);
                }
            } catch (error) {
                setIsAvailable(false);
            }
        };

        const interval = setInterval(checkAvailability, 10000);
        // Call the function once to set the initial state
        console.log("useEffect");
        checkAvailability();
        console.log("useEffect done");

        return () => {
            clearInterval(interval);
        };
    }, []);

    return isAvailable;
};

export default useApiAvailability;
