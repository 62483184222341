import {assertNotNull} from "../../utils/utils";
import {useState} from "react";
import {API_UPLOAD_URL} from "../../constants/constants";

const RecordingComponent = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isStopped, setIsStopped] = useState(false);

    const [recordedAudio, setRecordedAudio] = useState<Blob | string>("");
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [statusMessage, setStatusMessage] = useState<string>("");

    async function startRecording() {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        let myRecorder = new MediaRecorder(stream);
        assertNotNull(myRecorder, "myRecorder is null").start();
        setMediaRecorder(myRecorder);
        setIsRecording(true);
        setIsUploaded(false);
        setIsStopped(false);
        setStatusMessage("Recording started");
    }

    /**
     * Stops the recording and saves the audio to audioBlob state
     */
    async function stopRecording() {
        assertNotNull(mediaRecorder, "mediaRecorder is null").stop();
        const audioBlob = await new Promise<Blob>(resolve => {
            assertNotNull(mediaRecorder, "myRecorder is null")
                .addEventListener('dataavailable', (event: { data: Blob | PromiseLike<Blob>; }) => {
                    resolve(event.data);
                });
        });
        setRecordedAudio(audioBlob);
        setIsRecording(false);
        setStatusMessage("Recording stopped");
        setIsStopped(true);
        await uploadRecording(audioBlob);
    }

    /**
     * Uploads the current audioBlob state to the backend
     */
    async function uploadRecording(overrideRecordedAudio?: Blob) {
        setIsUploaded(true);
        setStatusMessage("Uploading recording ...");
        const data = new FormData();
        data.append('audio', overrideRecordedAudio || recordedAudio);
        const response = await fetch(API_UPLOAD_URL, {
            method: 'POST',
            body: data
        });
        setIsRecording(false);
        if (response.status === 200) {
            setStatusMessage("Recording uploaded");
        } else {
            setStatusMessage("Recording upload failed");
            setIsUploaded(false);
        }

    }

    return (
        <div className="audio-recorder">
            <div className="audio-recorder__controls">
                <button onClick={startRecording} disabled={isRecording}>Record</button>
                <button onClick={stopRecording} disabled={!isRecording}>Stop</button>
                {/*<button onClick={uploadRecording} disabled={(!isStopped) || (isUploaded)}>Upload</button>*/}
            </div>
            <div className="audio-recorder__status">
                {statusMessage}
            </div>
        </div>
    )
}

export default RecordingComponent;
