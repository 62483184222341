/**
 * Wrapper for any expression that should raise an error if it is null or undefined.
 * @param value
 * @param errorMessage
 */
export const assertNotNull = <T>(
    value: T | null,
    errorMessage: string
): T => {
    if (!value) {
        throw new Error(errorMessage);
    }
    return value;
};
